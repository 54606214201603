<template>
  <div>
    <component :is="block" />
  </div>
</template>

<script>
export default {
  components: {
    TransportsBlock: () => ({
      component: import("./transports"),
    }),
    InsuranceBlock: () => ({
      component: import("./insurance"),
    }),
    RepairBlock: () => ({
      component: import("./repair"),
    }),
  },
  computed: {
    block() {
      return this.$route.params.id + "-block";
    },
  },
};
</script>
